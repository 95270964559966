<template>
  <div />
</template>

<script>
import axios from '@/utils/xhr';

export default {
  mounted() {
    axios
      .get('/email/create')
      .then((response) => {
        this.$router.replace(`/email/${response.data.hash}`);
      })
      .catch(() => {
        this.$router.go(-1);
      });
  },
};
</script>
